import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from './supabaseConfig';
import { Card, Container, Row, Col, ListGroup, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const TenantDetail = () => {
  const [tenant, setTenant] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchTenant = async () => {
      const { data, error } = await supabase
        .from('tenants')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching tenant:', error);
      } else {
        setTenant(data);
      }
    };

    fetchTenant();
  }, [id]);

  const isExpired = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    return today >= end;
  };

  if (!tenant) {
    return <Container className="mt-5"><p>Loading...</p></Container>;
  }

  return (
    <Container className="mt-5 ">
      <Row className="justify-content-center max-w-4xl mx-auto">
        <Col md={8} lg={6}>
          <Card>
            <Card.Header as="h2" className="text-center bg-primary text-white">
              Member Details
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Name:</strong> {tenant.name}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Gym Plan:</strong> {tenant.apartment_number}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>End Date:</strong> {tenant.lease_end_date}
                  {' '}
                  <Badge bg={isExpired(tenant.lease_end_date) ? "danger" : "success"}>
                    {isExpired(tenant.lease_end_date) ? "Expired" : "Active"}
                  </Badge>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TenantDetail;