import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Input } from './ui/input';
import { Button } from './ui/button';

const TenantPage = ({ tenants }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTenants, setFilteredTenants] = useState(tenants);

  useEffect(() => {
    const filtered = tenants.filter(tenant => 
      (tenant.name && tenant.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (tenant.apartment_number && tenant.apartment_number.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (tenant.lease_end_date && tenant.lease_end_date.includes(searchTerm)) ||
      (tenant.phone_number && tenant.phone_number.includes(searchTerm))
    );
    setFilteredTenants(filtered);
  }, [searchTerm, tenants]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Member List</h1>
      <p className="mb-4">Total Members: {tenants.length}</p>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Search by name, phone, or end date"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full"
        />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Phone Number</TableHead>
            <TableHead>Gym Plan</TableHead>
            <TableHead>End Date</TableHead>
            <TableHead>Details</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredTenants.length > 0 ? (
            filteredTenants.map(tenant => (
              <TableRow key={tenant.id}>
                <TableCell>{tenant.name}</TableCell>
                <TableCell>{tenant.phone_number}</TableCell>
                <TableCell>{tenant.apartment_number}</TableCell>
                <TableCell>{tenant.lease_end_date}</TableCell>
                <TableCell>
                  <Link to={`/memberDetails/${tenant.id}`}>View Details</Link>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="5">
                <Alert>
                  <AlertTitle>No Members found</AlertTitle>
                  <AlertDescription>No members match your search criteria.</AlertDescription>
                </Alert>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default TenantPage;