import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-4  px-3">
      <Container fluid>
        <Row>
          <Col className='mt-5' md={4}>
            <p>Opposite Academic City , Bohy3</p>
            {/* <p>Email: info@company.com</p> */}
            <p>Phone: +233 24 348 7650</p>
          </Col>
          <Col md={4}>
            
          </Col>
          <Col className='mt-3' md={4}>
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li><a href="https://facebook.com" className="text-white">Facebook</a></li>
              <li><a href="https://twitter.com" className="text-white">Twitter</a></li>
              <li><a href="https://instagram.com" className="text-white">Instagram</a></li>
              {/* <li><a href="https://linkedin.com" className="text-white">LinkedIn</a></li> */}
            </ul>
          </Col>
        </Row>
        <div className="text-center py-3">
          © {new Date().getFullYear()} Citys Fitness. All rights reserved.
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
