import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useLocation, Redirect } from 'react-router-dom';
import { Navbar, Nav, Container, Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './LandingPage';
import AdminPage from './AdminPage';
import TenantPage from './TenantPage';
import TenantDetail from './TenantDetail';
import { getTenants, addTenant, updateTenant, deleteTenant } from './supabaseConfig';
import GymLogo from './images/gymlogo.png';

const ApartmentManagementApp = () => {
  const [tenants, setTenants] = useState([]);
  const [newTenant, setNewTenant] = useState({ name: '', apartment_number: 'BASIC', lease_end_date: '' });
  const [editingTenant, setEditingTenant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);

  useEffect(() => {
    const checkAdminAuth = () => {
      const adminAuth = localStorage.getItem('adminAuthenticated');
      if (adminAuth === 'true') {
        setIsAdminAuthenticated(true);
      }
    };

    checkAdminAuth();
    fetchTenants();

    const interval = setInterval(() => {
      checkExpiringLeases();
    }, 86400000);

    return () => clearInterval(interval);
  }, []);

  const fetchTenants = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchedTenants = await getTenants();
      setTenants(fetchedTenants || []);
    } catch (err) {
      setError('Failed to fetch tenants. Please try again later.');
      console.error('Error fetching tenants:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTenant = async () => {
    setError(null);
    try {
      if (editingTenant) {
        const updatedTenant = await updateTenant(editingTenant.id, newTenant);
        setTenants(tenants.map(t => t.id === updatedTenant.id ? updatedTenant : t));
        setEditingTenant(null);
      } else {
        const addedTenant = await addTenant(newTenant);
        setTenants([addedTenant, ...tenants]);
      }
      setNewTenant({ name: '', apartment_number: 'BASIC', lease_end_date: '' });

      const leaseEnd = new Date(newTenant.lease_end_date);
      const today = new Date();
      const timeDiff = leaseEnd.getTime() - today.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysDiff >= 10 && daysDiff <= 30) {
        alert(`${newTenant.name}'s Lease Ends: ${newTenant.lease_end_date} DO YOU WANT TO SEND THEM AN INVOICE?`);
      }
    } catch (err) {
      setError('Failed to add/update Member. Please try again.');
      console.error('Error adding/updating Member:', err);
    }
  };

  const handleEditTenant = (tenant) => {
    setEditingTenant(tenant);
    setNewTenant({ name: tenant.name, apartment_number: tenant.apartment_number, lease_end_date: tenant.lease_end_date });
  };

  const handleDeleteTenant = async (id) => {
    setTenantToDelete(id);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteTenant = async () => {
    setError(null);
    try {
      await deleteTenant(tenantToDelete);
      setTenants(tenants.filter(t => t.id !== tenantToDelete));
      setShowDeleteConfirmation(false);
      setTenantToDelete(null);
    } catch (err) {
      setError('Failed to delete tenant. Please try again.');
      console.error('Error deleting tenant:', err);
    }
  };

  const cancelDeleteTenant = () => {
    setShowDeleteConfirmation(false);
    setTenantToDelete(null);
  };

  const checkExpiringLeases = () => {
    const today = new Date();
    const expiringTenants = tenants.filter(tenant => {
      const leaseEnd = new Date(tenant.lease_end_date);
      const timeDiff = leaseEnd.getTime() - today.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return daysDiff >= 10 && daysDiff <= 30;
    });

    if (expiringTenants.length > 0) {
      alert(`Lease expiration alert: ${expiringTenants.map(tenant => tenant.name).join(', ')}`);
    }
  };

  const handleAdminClick = () => {
    if (!isAdminAuthenticated) {
      setShowAdminModal(true);
    }
  };

  const handleAdminSubmit = (e) => {
    e.preventDefault();
    if (adminPassword === process.env.REACT_APP_ADMIN_PASSWORD) {
      setIsAdminAuthenticated(true);
      setShowAdminModal(false);
      localStorage.setItem('adminAuthenticated', 'true');
    } else {
      alert('Incorrect password. Please try again.');
    }
    setAdminPassword('');
  };

  const handleSignOut = () => {
    setIsAdminAuthenticated(false);
    localStorage.removeItem('adminAuthenticated');
  };

  return (
    <Router>
      <AppContent
        tenants={tenants}
        setTenants={setTenants}
        newTenant={newTenant}
        setNewTenant={setNewTenant}
        editingTenant={editingTenant}
        setEditingTenant={setEditingTenant}
        isLoading={isLoading}
        error={error}
        handleAddTenant={handleAddTenant}
        handleEditTenant={handleEditTenant}
        handleDeleteTenant={handleDeleteTenant}
        isAdminAuthenticated={isAdminAuthenticated}
        showAdminModal={showAdminModal}
        setShowAdminModal={setShowAdminModal}
        adminPassword={adminPassword}
        setAdminPassword={setAdminPassword}
        handleAdminClick={handleAdminClick}
        handleAdminSubmit={handleAdminSubmit}
        handleSignOut={handleSignOut}
        showDeleteConfirmation={showDeleteConfirmation}
        confirmDeleteTenant={confirmDeleteTenant}
        cancelDeleteTenant={cancelDeleteTenant}
      />
    </Router>
  );
};

const AppContent = ({
  tenants,
  setTenants,
  newTenant,
  setNewTenant,
  editingTenant,
  setEditingTenant,
  isLoading,
  error,
  handleAddTenant,
  handleEditTenant,
  handleDeleteTenant,
  isAdminAuthenticated,
  showAdminModal,
  setShowAdminModal,
  adminPassword,
  setAdminPassword,
  handleAdminClick,
  handleAdminSubmit,
  handleSignOut,
  showDeleteConfirmation,
  confirmDeleteTenant,
  cancelDeleteTenant
}) => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('lastRoute', location.pathname);
  }, [location]);

  const InitialRouteHandler = ({ isAdminAuthenticated }) => {
    const location = useLocation();
    const lastRoute = localStorage.getItem('lastRoute');

    useEffect(() => {
      if (lastRoute && lastRoute !== '/') {
        if ((lastRoute === '/admin' || lastRoute === '/members') && !isAdminAuthenticated) {
          window.history.replaceState(null, '', '/');
        } else {
          window.history.replaceState(null, '', lastRoute);
        }
      }
    }, [lastRoute, isAdminAuthenticated]);

    return null;
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className=''>
        <Container fluid className='mx-4 px-4'>
          <Navbar.Brand as={Link} to="/"> <img src={GymLogo} alt='Cityfitness' style={{ width: '200px', height: 'auto' }}/> </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/admin" onClick={handleAdminClick}>Admin</Nav.Link>
              {isAdminAuthenticated && <Nav.Link as={Link} to="/members">Members</Nav.Link>}
              <Nav.Link href="#contact">Contact</Nav.Link>
              {isAdminAuthenticated && <Nav.Link onClick={handleSignOut}>Sign Out</Nav.Link>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className=" ">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/admin">
            {isAdminAuthenticated ? (
              <AdminPage
                tenants={tenants}
                setTenants={setTenants}
                newTenant={newTenant}
                setNewTenant={setNewTenant}
                editingTenant={editingTenant}
                setEditingTenant={setEditingTenant}
                isLoading={isLoading}
                error={error}
                handleAddTenant={handleAddTenant}
                handleEditTenant={handleEditTenant}
                handleDeleteTenant={handleDeleteTenant}
                showDeleteConfirmation={showDeleteConfirmation}
                confirmDeleteTenant={confirmDeleteTenant}
                cancelDeleteTenant={cancelDeleteTenant}
              />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/members">
            {isAdminAuthenticated ? (
              <TenantPage tenants={tenants} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/memberDetails/:id">
            <TenantDetail tenants={tenants} />
          </Route>
          <Route path="*">
            <InitialRouteHandler isAdminAuthenticated={isAdminAuthenticated} />
          </Route>
        </Switch>
      </div>

      <Modal show={showAdminModal} onHide={() => setShowAdminModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Admin Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAdminSubmit}>
            <Form.Group>
              <Form.Label>Enter Admin Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={adminPassword}
                onChange={(e) => setAdminPassword(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApartmentManagementApp;