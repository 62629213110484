// supabaseConfig.js
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://nrkyosxsnyoeiparbybi.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5ya3lvc3hzbnlvZWlwYXJieWJpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM5MDI2NTIsImV4cCI6MjAzOTQ3ODY1Mn0.YrKstBed0DimwT7BUlXRWRYFQ32uQoJZeQkiH6k8_Gk'

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const authenticateAdmin = (password) => {
  const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;
  if (!adminPassword) {
    console.error('Admin password not set in environment variables');
    return false;
  }
  return password === adminPassword;
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) throw error
}

export const getSession = async () => {
  const { data, error } = await supabase.auth.getSession()
  if (error) throw error
  return data.session
}

export const getTenants = async () => {
  let { data: tenants, error } = await supabase
    .from('tenants')
    .select('*')
    .order('created_at', { ascending: false })
  
  if (error) {
    console.error('Error fetching tenants:', error)
    throw error
  }
  return tenants
}

export const addTenant = async (tenant) => {
  const { data, error } = await supabase
    .from('tenants')
    .insert([{ ...tenant, created_at: new Date().toISOString() }])
    .select()
  
  if (error) {
    console.error('Error adding tenant:', error)
    throw error
  }
  return data[0]
}

export const updateTenant = async (id, updates) => {
  const { data, error } = await supabase
    .from('tenants')
    .update(updates)
    .eq('id', id)
    .select()
  
  if (error) {
    console.error('Error updating tenant:', error)
    throw error
  }
  return data[0]
}

export const deleteTenant = async (id) => {
  const { data, error } = await supabase
    .from('tenants')
    .delete()
    .eq('id', id)
  
  if (error) {
    console.error('Error deleting tenant:', error)
    throw error
  }
  return id
}