import React, { useState, useEffect } from 'react';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './ui/table';
import { Container, Modal } from 'react-bootstrap';

const AdminPage = ({ 
  tenants, 
  setTenants, 
  newTenant, 
  setNewTenant, 
  editingTenant, 
  setEditingTenant, 
  isLoading, 
  error, 
  handleAddTenant, 
  handleEditTenant, 
  handleDeleteTenant,
  showDeleteConfirmation,
  confirmDeleteTenant,
  cancelDeleteTenant
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTenants, setFilteredTenants] = useState(tenants);

  useEffect(() => {
    const filtered = tenants.filter(tenant => 
      (tenant.name && tenant.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (tenant.apartment_number && tenant.apartment_number.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (tenant.lease_end_date && tenant.lease_end_date.includes(searchTerm)) ||
      (tenant.phone_number && tenant.phone_number.includes(searchTerm))
    );
    setFilteredTenants(filtered);
  }, [searchTerm, tenants]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Container>
      <div>
        <h2>Admin Dashboard</h2>
        <div className="mb-4 flex space-x-2">
          <Input
            placeholder="Member Name"
            value={newTenant.name}
            onChange={(e) => setNewTenant({ ...newTenant, name: e.target.value })}
          />
          <Input
            placeholder="Phone Number"
            value={newTenant.phone_number}
            onChange={(e) => setNewTenant({ ...newTenant, phone_number: e.target.value })}
          />
          <Select
            value={newTenant.apartment_number}
            onValueChange={(value) => setNewTenant({ ...newTenant, apartment_number: value })}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select Gym Plan" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="BASIC">BASIC</SelectItem>
              <SelectItem value="VIP">VIP</SelectItem>
            </SelectContent>
          </Select>
          <Input
            type="date"
            value={newTenant.lease_end_date}
            onChange={(e) => setNewTenant({ ...newTenant, lease_end_date: e.target.value })}
          />
          <Button onClick={handleAddTenant}>{editingTenant ? 'Update' : 'Add'} Member</Button>
        </div>

        {error && (
          <Alert className="mb-4">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="mb-4">
          <Input
            type="text"
            placeholder="Search by name, phone, gym plan, or end date"
            value={searchTerm}
            onChange={handleSearch}
            className="w-full"
          />
        </div>

        {isLoading ? (
          <p>Loading Members...</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Phone Number</TableHead>
                <TableHead>Gym Plan</TableHead>
                <TableHead>End Date</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredTenants.map(tenant => (
                <TableRow key={tenant.id}>
                  <TableCell>{tenant.name}</TableCell>
                  <TableCell>{tenant.phone_number}</TableCell>
                  <TableCell>{tenant.apartment_number}</TableCell>
                  <TableCell>{tenant.lease_end_date}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleEditTenant(tenant)} className="mr-2">Edit</Button>
                    <Button onClick={() => handleDeleteTenant(tenant.id)} variant="destructive">Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {!isLoading && filteredTenants.length === 0 && (
          <Alert className="mt-4">
            <AlertTitle>No Members found</AlertTitle>
            <AlertDescription>No members match your search criteria.</AlertDescription>
          </Alert>
        )}

        <Modal show={showDeleteConfirmation} onHide={cancelDeleteTenant}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelDeleteTenant}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDeleteTenant}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default AdminPage;